import { styled } from '@mui/system'

const FacebookStyled = styled('div')`
	.Facebook-button {
		background: ${({ theme }) => theme.palette.social.facebook};
		border: 0;
		border-radius: ${({ theme }) => theme.shape.borderRadius}px;
		display: flex;
		justify-content: center;
		color: ${({ theme }) => theme.palette.white.main};
		width: 100%;
		position: relative;
		cursor: pointer;
		min-height: 60px;
		transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		${({ theme }) => theme.typography.button}
		text-transform: none;
		.MuiButton-startIcon {
			position: absolute;
			left: 25px;
			> .MuiSvgIcon-root {
				font-size: ${({ theme }) => theme.typography.pxToRem(20)};
			}
		}
		&:hover {
			background-color: ${({ theme }) => theme.palette.social.facebookHover};
		}
		&[disabled] {
			opacity: 0.3;
			cursor: auto;
		}
	}
`

export { FacebookStyled }
