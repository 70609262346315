import Form from '@bluheadless/ui-logic/src/components/form/form'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useCustomerNewsletter } from '@bluheadless/ui-logic/src/hooks/customer/useCustomerNewsletter'
import { useCustomerNewsletterSubscribe } from '@bluheadless/ui-logic/src/hooks/customer/useCustomerNewsletterSubscribe'
import { useFormFields } from '@bluheadless/ui-logic/src/hooks/form/useFormFields'
import { useNotification } from '@bluheadless/ui-logic/src/hooks/useNotification'
import { useUserActions } from '@bluheadless/ui-logic/src/providers/user/user-actions'
import { useRewardInvitationCheck } from '@bluheadless/ui-logic/src/hooks/reward/useRewardInvitationCheck'
import { useRewardInvitationConfirm } from '@bluheadless/ui-logic/src/hooks/reward/useRewardInvitationConfirm'
import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'prop-types'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { object as yupObject, string as yupString } from 'yup'
import { useRouter } from 'next/router'
import FormFields, { nicknameFieldName, imageFieldName } from './form-fields'
import dayjs from 'dayjs'
import { useNewsletter } from '@bluheadless/ui-logic/src/hooks/newsletter/useNewsletter'

const RegisterForm = ({ formProps, source, redirect, redirectTo }) => {
	const { enqueueNotification } = useNotification()
	const { register, registerError } = useUserActions()
	const {
		newsletter: { enabled: newsletterEnabled },
		rewardPoints: { enabled: rewardPointsEnabled, uniqueUrl, enableInvites: invitesEnabled },
		customer: {
			register: {
				additionalFields: { dob },
			},
		},
	} = useConfig()

	const { mutate: mutateNewsletter } = useCustomerNewsletter()
	const { action: subscribeNewsletter } = useCustomerNewsletterSubscribe()
	const { action: subscribe } = useNewsletter()
	const {
		query: { referral_code, invitation_code },
	} = useRouter()
	const { email: invitationEmail } = useRewardInvitationCheck({
		code: invitation_code,
		paused: !rewardPointsEnabled || !invitesEnabled || uniqueUrl,
	})

	const { action: confirmInvitationCode } = useRewardInvitationConfirm()

	const { formatMessage } = useIntl()

	const {
		email,
		firstName,
		lastName,
		password,
		passwordConfirm,
		passwordNew,
		privacyNewsletter,
		privacyPolicy: privacyPolicyField,
		recaptchaToken,
		recaptchaAction,
		referralCode,
		...additionalFields
	} = useFormFields()

	const handleSubmit = async (fields) => {
		let profile_image = ''
		if (fields[imageFieldName]?.length === 0) {
			profile_image = '' // delete photo
		} else if (fields[imageFieldName][0]?.base64) {
			profile_image = fields[imageFieldName][0]?.base64 // new photo
		} else {
			profile_image = undefined // keep old photo
		}

		const formFields = {
			[email.name]: fields.email,
			[firstName.name]: fields.firstname,
			[lastName.name]: fields.lastname,
			[password.name]: fields.password,
			[privacyNewsletter.name]: newsletterEnabled
				? fields.privacy_newsletter
					? fields.privacy_newsletter
					: false
				: false,
			[privacyPolicyField.name]: fields.privacy_policy ? fields.privacy_policy : false,
			source,
			redirect,
			redirectTo,
			[recaptchaToken.name]: fields.recaptchaToken,
			[recaptchaAction.name]: fields.recaptchaAction,
			...(dob?.enabled && fields.dob ? { [additionalFields.dob.name]: dayjs(fields.dob).format('YYYY-MM-DD') } : {}),
			additionalData: {
				[nicknameFieldName]: fields[nicknameFieldName],
				[imageFieldName]: profile_image,
				...(rewardPointsEnabled && invitesEnabled && uniqueUrl && referral_code
					? { [referralCode.name]: referral_code }
					: {}),
			},
		}

		if (await register({ formData: formFields })) {
			if (formFields.privacy_newsletter) {
				const fn = async () => {
					if (formFields?.dob) {
						await subscribe({
							formData: {
								email: formFields.email,
								firstName: formFields.firstname,
								lastName: formFields.lastname,
								...(formFields?.dob ? { [additionalFields.dob.name]: formFields.dob } : {}),
							},
							source: formFields.source,
						})
					}
					await subscribeNewsletter({
						source: formFields.source,
					})
				}
				await mutateNewsletter(await fn(), { revalidate: true })
			}

			if (rewardPointsEnabled && invitesEnabled && !uniqueUrl && invitation_code && invitationEmail) {
				await confirmInvitationCode({ code: invitation_code })
			}

			enqueueNotification({ uiProps: { message: formatMessage({ id: 'register_submit_success' }) } })
		}
	}

	const formDefaultValues = {
		[email.name]: '',
		[firstName.name]: '',
		[lastName.name]: '',
		[privacyNewsletter.name]: false,
		[privacyPolicyField.name]: false,
		...(dob?.enabled ? { [additionalFields.dob.name]: '' } : {}),
		[nicknameFieldName]: '',
	}

	const schemaDefault = yupObject(
		{
			[email.name]: email.validation,
			[firstName.name]: firstName.validation,
			[lastName.name]: lastName.validation,
			[password.name]: passwordNew.validation,
			[passwordConfirm.name]: passwordConfirm.validation,
			[privacyPolicyField.name]: privacyPolicyField.validation,
			...(dob?.enabled
				? {
						[additionalFields.dob.name]: dob?.required
							? additionalFields.dob.validation
							: additionalFields.dob.validation.notRequired(),
				  }
				: {}),
			[nicknameFieldName]: yupString().required(),
			[imageFieldName]: null,
		},
		[]
	)

	useEffect(() => {
		if (registerError) {
			enqueueNotification({ uiProps: { severity: 'error', message: registerError.message } })
		}
	}, [enqueueNotification, registerError])

	return (
		<>
			<Form
				noValidate
				options={{
					resolver: yupResolver(schemaDefault),
					defaultValues: formDefaultValues,
				}}
				onSubmit={handleSubmit}
				{...formProps}
				captcha
			>
				<FormFields invitationEmail={invitationEmail} />
			</Form>
		</>
	)
}

RegisterForm.propTypes = {
	formProps: object,
}

export default RegisterForm
