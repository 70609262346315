import { useFormFields } from '@bluheadless/ui-logic/src/hooks/form/useFormFields'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { bool, string } from 'prop-types'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import Typography from '../../../../atoms/typography'
import ControlledCheckbox from '../../../../molecules/checkbox/controlled-checkbox'
import { ControlledInput } from '../../../../molecules/input/input'
import ControlledFileUpload from '../../../../molecules/file-upload/controlled-file-upload'
import RichContent from '../../../../particles/rich-content/rich-content'
import { CheckBoxWrapper, SubmitButtonStyled } from './register-form.styled'
import dynamic from 'next/dynamic'

const ControlledDateTimePicker = dynamic(() =>
	import('../../../../molecules/date-picker').then((mod) => mod.ControlledDateTimePicker)
)

export const nicknameFieldName = 'nickname'
export const imageFieldName = 'profile_image'
const imageAcceptedExtensions = ['.jpg', '.jpeg', '.png']

const FormFields = ({ invitationEmail }) => {
	const {
		email,
		firstName,
		lastName,
		password,
		passwordConfirm,
		privacyNewsletter,
		privacyPolicy: privacyPolicyField,
		...additionalFields
	} = useFormFields()

	const {
		disclaimers: { privacyPolicy, marketing },
		newsletter: { enabled: newsletterEnabled },
		customer: {
			register: {
				additionalFields: { dob },
			},
		},
	} = useConfig()

	const { formatMessage } = useIntl()

	const { setValue } = useFormContext()

	useEffect(() => {
		if (invitationEmail) {
			setValue(email.name, invitationEmail)
		}
	}, [email.name, invitationEmail, setValue])

	return (
		<>
			<ControlledInput name={nicknameFieldName} label={formatMessage({ id: `forms_${nicknameFieldName}` })} required />
			<ControlledInput name={firstName.name} id="firstname" label={firstName.label} required />
			<ControlledInput name={lastName.name} id="lastname" label={lastName.label} required />

			{dob?.enabled && (
				<ControlledDateTimePicker
					name={additionalFields.dob.name}
					id="dob_register"
					label={additionalFields.dob.label}
					required={dob.required}
					maxDate={new Date()}
				/>
			)}

			<ControlledInput
				name={email.name}
				id="email_register"
				label={email.label}
				required
				autoComplete="email"
				inputProps={{ readOnly: !!invitationEmail }}
			/>
			<Typography variant="label" mb={1} display="block">
				{formatMessage({ id: `forms_${imageFieldName}` })}
			</Typography>
			<ControlledFileUpload
				name={imageFieldName}
				multiple={false}
				maxFiles={1}
				accept={imageAcceptedExtensions}
				useChipsForPreview={false}
			/>
			<ControlledInput
				name={password.name}
				id="password_register"
				label={password.label}
				type="password"
				autoComplete="new-password"
				required
			/>
			<ControlledInput
				name={passwordConfirm.name}
				id="passwordConfirm"
				label={passwordConfirm.label}
				type="password"
				required
				autoComplete="confirm-password"
			/>
			<CheckBoxWrapper className="privacy-checkbox">
				<ControlledCheckbox
					required
					name={privacyPolicyField.name}
					label={
						<Typography variant="checkbox" color="grey.main" component="div">
							<RichContent content={privacyPolicy} />
						</Typography>
					}
				/>
			</CheckBoxWrapper>

			{newsletterEnabled && (
				<CheckBoxWrapper>
					<ControlledCheckbox
						name={privacyNewsletter.name}
						label={
							<Typography variant="checkbox" color="grey.main" component="div">
								<RichContent content={marketing} />
							</Typography>
						}
					/>
				</CheckBoxWrapper>
			)}

			<SubmitButtonStyled fullWidth label={formatMessage({ id: 'register_submit' })} />
		</>
	)
}

FormFields.propTypes = {
	registerLoading: bool,
	invitationEmail: string,
}

export default FormFields
